




























import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      item: null,
      userManager,
      currentTab: "",
      tabs: {
        index: {
          label: "Tổng thống kê",
          icon: "mdi-account-group-outline",
        },
        telesale: {
          label: "Báo cáo telesale",
          icon: "mdi-phone",
          condition() {
            return userManager.checkRole([
              "admin",
              "kt",
              "qlk",
              "vd",
              "vdl",
              "telesale",
            ]);
          },
        },
        users: {
          label: "Thống kê nhân viên",
          icon: "mdi-account-outline",
        },
      },
    };
  },
  async created() {
    this.item = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
  },
});
